.container {
  margin: var(--spacing-5) 0;
  padding: var(--spacing-5);
  background-color: var(--color-sunbeam-blue-50);
}

.title {
  margin: 0 0 var(--spacing-5) 0;
}

.content {
  @media (--tablet) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.subtitle {
  line-height: 23px;
}

.button {
  width: 100% !important;
  margin: var(--spacing-3) 0 0 0 !important;
  @media (--tablet) {
    max-width: 257px !important;
    margin: 0 0 0 var(--spacing-10) !important;
  }
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
}

& [data-tenant='rac'] {
  .container {
    background-color: white;
    border: 1px solid var(--color-primary-50);
  }
}
