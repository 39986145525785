.search {
  padding: var(--spacing-5);

  display: grid;
  box-shadow: var(--shadow-s);
  background: var(--color-championship-white);
  grid-template-rows: auto auto;
  gap: var(--spacing-6);
  .searchCriteriaRow {
    display: grid;
    align-items: start;

    &[data-columns-type='double-column'] {
      grid-template-columns: 2fr 1fr;
    }
    &[data-columns-type='single-column'] {
      grid-template-columns: 1fr;
    }
  }

  .searchCriteria {
    display: flex;
    gap: var(--spacing-2);
    overflow: hidden;
    max-height: 64px;
    flex-wrap: wrap;
    &[data-is-expanded='true'] {
      max-height: none;
    }
    & span {
      padding: var(--spacing-1) var(--spacing-2);
    }
  }
  .moreButton {
    align-self: end;
  }
  .actions {
    display: flex;
    gap: var(--spacing-3);
    justify-content: flex-end;
    align-self: end;
    & button {
      flex: 1 1 54px;
    }
    & .viewresults {
      flex: 1 1 80%;
    }
  }
}

.modalBodyClassName {
  && {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    padding-bottom: var(--spacing-5);
    padding-top: var(--spacing-5);

    @media (--tablet) {
      padding-bottom: var(--spacing-8);
      padding-top: var(--spacing-8);
    }
  }
}
