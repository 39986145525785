.addressWrapper p {
  margin: 0;
}

.wrapper {
  margin-top: -4px;
}

.deliveryLink {
  font-size: 14px;
  color: var(--color-primary-500) !important;
}

.policyText {
  font-size: 16px;
}
