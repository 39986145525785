.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  padding-bottom: var(--spacing-6);
}

.sections {
  width: 100%;
  display: grid;
  gap: var(--spacing-6);
  margin-top: var(--spacing-6);
  grid-template-columns: 1fr;

  @media (--tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--tablet-l) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.title {
  margin-bottom: 0;
}

.section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
}

.sectionHeading {
  margin: var(--spacing-2) 0;
}

.ctas {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    font-size: 0.875em;
    text-decoration: none;
    color: var(--color-primary);
  }
}

.collapsibleSection {
  display: flex;
  flex-direction: column;
}

.collapsibleSectionContent {
  margin-top: var(--spacing-6);
}

.viewMoreButton {
  margin-top: var(--spacing-3);
  align-self: flex-start;
  padding-left: 0;
  min-height: unset;
}
