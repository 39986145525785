.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.optionContent {
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  line-height: var(--spacing-6);
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.leftContent {
  flex: 0 0 auto;
  margin-right: var(--spacing-1);

  & > svg {
    display: block;
  }
}

.rightContent {
  flex: 0 0 auto;
}
