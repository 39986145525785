.wrapper {
  display: grid;
  grid-gap: var(--spacing-5);
}

.grid {
  display: grid;
  grid-gap: var(--spacing-5);
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

  @media (--desktop) {
    grid-template-columns: repeat(3, 1fr);
  }
}
