.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.bodyText {
  @media (--tablet-l) {
    max-width: 594px;
  }
  @media (--desktop-l) {
    max-width: 868px;
  }
}

.ctas {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);

  @media (--tablet) {
    flex-direction: row;
    gap: var(--spacing-6);
  }
}

.central {
  width: 100%;
  justify-content: center;
}

.button {
  width: 100%;
  @media (--tablet) {
    width: 348px;
  }
  @media (--desktop-l) {
    width: 421px;
  }
}

.primary {
  @media (--tablet) {
    order: 2;
  }
}
