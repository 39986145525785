.parentWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 100%;
}

.wrapper {
  width: 100%;

  @media (--tablet) {
    max-width: 344px;
  }

  @media (--desktop) {
    max-width: 312px;
  }

  border: solid 1px var(--color-tarmac-grey-400);
  transition: border 0.3s;
  padding-left: var(--spacing-1);

  &[data-has-focus='true'] {
    border-color: var(--color-primary-400);
  }

  &[data-has-error='true'] {
    margin-bottom: 26px;
    border-color: var(--color-red-400);
  }

  & input {
    max-width: 25px;
    padding: var(--spacing-3) 0 var(--spacing-2);
    border: none;
    outline: none;
    appearance: none;
    border-radius: 0;
    font-weight: normal;
  }
  & fieldset {
    border: none;
  }
}

.label {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--spacing-3);

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.hyphen {
  position: relative;
  top: 2px;
  left: -2px;
}

.labelText {
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 3px;
  background-color: white;
  color: var(--color-tarcmac-grey-400);
  transform: translateY(-50%) translateY(-20px) scale(0.85);
  input:focus ~ & {
    color: var(--color-primary-400);
  }

  &[data-has-error='true'] {
    color: var(--color-red-400);
  }
}

.error {
  margin-bottom: 15px;
  margin-top: -22px;
  margin-left: var(--spacing-1);
  display: block;
  color: var(--color-red-400);
}
