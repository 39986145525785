.emailInput {
  display: flex;
  gap: var(--spacing-2);
  align-items: flex-start;
  justify-content: space-between;
  @media (--mobile) {
    & button {
      width: 100%;
    }
    height: auto;
    flex-direction: column;
  }
}

.signUpSuccessMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-2);
  @media (--mobile) {
    margin-bottom: var(--spacing-6);
  }
}
