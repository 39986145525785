.wrpr {
  & > span {
    display: block;
    padding: var(--spacing-3) 0;
  }
}

.linksCont {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2) var(--spacing-3);

  & > a,
  & > span {
    font-weight: 700;
    font-size: 14px;
    color: var(--color-neutral-900);
  }

  & > span {
    color: var(--color-neutral-300);
  }
}
