.quickFiltersWrapper {
  position: sticky;
  top: var(--spacing-16);
  z-index: 2;
  display: flex;
  align-items: center;
  margin: 0 -24px 0 -24px;
  padding: var(--spacing-2) var(--spacing-5);
  background: #fff;
  overflow: auto;
  white-space: nowrap;

  box-shadow: 0px 2px 4px 0px #2626260f;

  &::-webkit-scrollbar {
    display: none;
  }

  &[data-has-default-header='true'] {
    @media (--tablet) {
      top: var(--spacing-18);
    }

    @media (--tablet-l) {
      top: 112px;
    }
  }

  @media (--tablet-l) {
    margin: 0 -32px 0 -32px;
    top: 72px;
    padding: var(--spacing-2) var(--spacing-7);
  }

  @media (--desktop) {
    display: none;
  }

  [data-service-consumer='native'] & {
    top: 0;
  }
  [data-has-footer-sticky='true'] & {
    top: 0;
  }
}

.postcodeInputStyling {
  justify-content: space-between;

  & .pill {
    width: 100%;
  }
}

.pill {
  border-radius: var(--spacing-1);
  cursor: pointer;
  margin: 0 var(--spacing-1);
  padding: 6px 12px !important;
  background-color: white;
  border: 1px solid #b5b5b5;
  height: var(--spacing-8);
  gap: var(--spacing-1);

  & .selected {
    background-color: blue !important;
  }
}

.pillSkeleton {
  margin: var(--spacing-2) var(--spacing-1);
  gap: var(--spacing-1);
}

.pill.filtersSelected {
  background-color: var(--pill-background-color);
  border: 0;
}

.pill.selected {
  background-color: #f1f4fa;
  border: 1px solid #164ca3;
}

.pill.providePostcode {
  border: none;
}

.stickyFilters {
  @media (--desktop) {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-overlay);
  z-index: 100;
}

.stickyFilterMenus {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  padding: 0 var(--spacing-5);
  max-height: 80%;
}

.filterHeader {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px -24px 8px -24px;
  padding: 0px var(--spacing-6);
  width: calc(100% + 48px);
}

.filterHeader h6 {
  margin: var(--spacing-4) 0;
}

.greyed {
  color: var(--color-neutral-300);
  pointer-events: none;
}

.sort {
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
  }

  @media (--desktop) {
    display: none;
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
  margin: 0 var(--spacing-2);
  align-items: center;

  * {
    font-size: 12px;
  }
}

.divider {
  border-right: 1px solid var(--color-neutral-300);
  height: 14px;
  display: flex;
}

.clearAll {
  color: var(--color-neutral-900);
}
