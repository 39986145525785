.topRecentArticles {
  & .header {
    row-gap: var(--spacing-2);
  }

  & .headline {
    & h2 {
      margin: 0;
    }
  }

  & .header + .articles {
    margin-top: var(--spacing-6);
  }

  & .articles {
    row-gap: var(--spacing-6);
  }
}

@media (--tablet) {
  .topRecentArticles {
    & .header + .articles {
      margin-top: var(--spacing-8);
    }
  }
}

@media (--desktop) {
  .topRecentArticles {
    & .article {
      display: none;

      &:nth-child(-n + 6) {
        display: unset;
      }
    }

    & > div:has(+ div) {
      & > div {
        & .article {
          &:nth-child(-n + 3) {
            display: unset;
          }
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }
    }
  }
}
