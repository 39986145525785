.wrapper {
  margin: var(--spacing-10) 0;
}

.emailInput {
  display: flex;
  gap: var(--spacing-2);
  margin-top: var(--spacing-2);
  align-items: flex-start;
  justify-content: space-between;
  @media (--mobile) {
    & button {
      width: 100%;
    }
    height: auto;
    flex-direction: column;
  }
}

.gridContainer {
  padding-right: var(--spacing-2);
  padding-left: var(--spacing-2);
}

.container {
  padding: var(--spacing-6);
  background: #f1f4fa;
  --internalGap: 0;

  @media (--mobile) {
    padding: var(--spacing-4);
  }
}

.privacyCaption {
  margin-top: var(--spacing-2);
}

.optInBannerText {
  display: flex;
  flex-direction: column;
  padding-top: var(--spacing-4);
  padding-right: var(--spacing-4);

  @media (--mobile) {
    padding-top: 0;
    padding-right: 0;
  }
}

.contentBody {
  display: flex;
  align-items: center;
}

.optInBannerContent {
  display: flex;
  justify-content: center;
  align-items: left;
  margin: 0;

  & img {
    margin-right: var(--spacing-6);
  }

  & h4 {
    margin: 0;
    margin-bottom: 1rem;
  }

  & p {
    margin: 0;
    font-weight: 500;

    @media (--mobile) {
      padding-right: var(--spacing-2);
    }
  }
}

.mobileButton {
  padding-right: 0;
}

.signUpSuccess {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-2);
  @media (--mobile) {
    margin-bottom: var(--spacing-6);
  }
}
