.modalContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    height: unset;
    width: 100%;
  }
  @media (--tablet-l) {
    height: unset;
    width: 800px;
  }
}

.modalBodyClassName {
  && {
    padding: 0;
  }
}

.dealerFormHeader {
  && {
    margin: 0;
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modalBody {
  overflow-y: auto;
  padding: var(--spacing-8) var(--spacing-5) 0 var(--spacing-5);
  max-height: calc(100vh - 64px);

  @media (--tablet) {
    max-height: calc(100vh - 64px);
    padding: var(--spacing-8) var(--spacing-6) 0 var(--spacing-6);
  }
  @media (--tablet-l) {
    max-height: calc(100vh - 180px);
    padding: var(--spacing-8) var(--spacing-10) 0 var(--spacing-10);
  }
}

.modalFooter {
  position: sticky;
  padding: var(--spacing-6) 0;
}

.enrichmentWrapper {
  margin-bottom: var(--spacing-4);
}
