.bubble {
  &.noImage {
    background: var(--color-mica-blue-50);
    border: none;
  }
  position: relative;
  width: var(--spacing-16);
  height: var(--spacing-16);
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 var(--spacing-2);
  border: 1px solid var(--color-tarmac-grey-100);
  flex-shrink: 0;
  @media (--tablet-l) {
    width: 80px;
    height: 80px;
  }

  & img {
    width: 100%;
    object-fit: contain;
    padding: var(--spacing-1);
  }
}

& [data-tenant='rac'] {
  & .bubble {
    background-color: white;
    border: 1px solid var(--color-rac-grey-10);

    &.noImage {
      background-color: white;
      border: 1px solid var(--color-rac-grey-10);
    }
  }
}
