.video {
  & a {
    color: var(--color-tarmac-grey-900);
  }
}

.buttonGroup {
  gap: var(--spacing-3);

  & button {
    margin-bottom: 0;
  }
}
