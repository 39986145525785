.wrapper {
  max-width: 1312px;
  padding: var(--spacing-10) 0 var(--spacing-10) 0;
}

.helpWrapper {
  text-align: center;
}
.wrapper {
  max-width: 1312px;
}

.headline {
  margin-bottom: 24px;
  @media (--tablet-l) {
    margin-bottom: 40px;
  }
}

.innerWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (--tablet) {
    flex-direction: row;
    & > li {
      width: 32% !important;
      padding: var(--spacing-4) !important;
      margin-bottom: 0px;
    }
  }
  @media (--tablet-l) {
    flex-direction: row;
    & > li {
      padding: var(--spacing-10) !important;
      margin-bottom: 0px;
    }
  }
  & > li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-sunbeam-blue-50);
    width: 100%;
    padding: var(--spacing-4) !important;
    margin-bottom: var(--spacing-3);

    & > .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-sunbeam-blue-500);
      width: 64px;
      height: 64px;
      border-radius: 60px;
      margin-bottom: var(--spacing-5);
      position: relative;

      & > img {
        width: 40px;
        object-fit: contain;
        object-position: center;
        padding: var(--spacing-3);
      }
    }

    & > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > h3 {
        font-size: 20px;
        margin-bottom: var(--spacing-3);
        text-align: center;
      }

      & p {
        text-align: center;
        font-size: 16px;
        width: 200px;

        & > a {
          color: var(--neutral-700);
          &::after {
            background-color: var(--neutral-700);
          }
        }
      }
    }
  }
}

.ctaWrapper {
  display: flex;
  justify-content: center;
  padding: var(--spacing-5) 0 var(--spacing-5) 0;
}

.cta {
  width: 300px;
}
