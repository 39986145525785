.container {
  position: relative;
  width: 100%;
  color: var(--option-text-color);

  &:focus {
    border-color: var(--container-focus-border-color);
  }

  &.disabled {
    color: var(--container-disabled-color);
    border-color: var(--container-disabled-color);
  }

  &.disabled:focus {
    color: var(--container-disabled-color);
    border-color: var(--container-disabled-color);
  }

  /* Error */
  & span[role='alert'] {
    padding-left: var(--spacing-3);
    font-size: 12px;
  }

  &.isOpen {
    & fieldset {
      border-color: var(--options-border-color);
      border-radius: var(--border-radius) var(--border-radius) 0px 0px;
    }
  }

  input[data-readonly] {
    pointer-events: none;
  }
}

.value {
  flex-grow: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  padding-left: var(--spacing-3);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:read-only {
    color: inherit;
    cursor: pointer;
  }
}

.options {
  position: absolute;
  top: 48px;
  z-index: 100;
  display: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 15em;
  list-style: none;
  overflow-y: auto;
  border: 1px solid var(--options-border-color);
  border-top: none;
  background-color: var(--color-championship-white);

  &.menuBottomPosition {
    display: block;
    border-radius: 0px 0px var(--border-radius) var(--border-radius);
  }

  &.menuTopPosition {
    top: -141px;
    border-radius: 0px 0px var(--border-radius) var(--border-radius);
    display: block;
  }
}

.option {
  padding: var(--spacing-3);
  cursor: pointer;
  max-height: var(--spacing-12);

  &.selected {
    padding-left: var(--spacing-2);
    border-left: solid var(--spacing-1) var(--color-neutral-700);
  }

  /* highlighted is the class for programitically hovered options */
  &.highlighted,
  &:hover {
    background-color: var(--color-neutral-50);
  }
}

.placeholderOption {
  color: var(--color-tarmac-grey-300);
  cursor: default;

  &:hover,
  &.highlighted {
    background-color: transparent;
  }
}
