.searchWrapper {
  border: 1px solid var(--color-tarmac-grey-900);
  position: sticky;
  background: var(--color-championship-white);
  top: 0;
  overflow: hidden;
  z-index: 1;

  /* Selecting the fieldset inside in Input component (this is where the Input's border is set) */
  & > div > div > fieldset {
    border: none;
  }
}

.clearIcon {
  cursor: pointer;
  transform: rotate(45deg);

  circle {
    fill: var(--color-tarmac-grey-500);
  }
}

.icon {
  & .default {
    display: none;
    margin-bottom: -6px;

    @media (--tablet) {
      display: block;
    }
  }

  & .mobile {
    margin-bottom: -6px;

    @media (--tablet) {
      display: none;
    }
  }
}
