.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--color-championship-white);
  padding: var(--spacing-5);
  gap: var(--spacing-4);
  h5,
  h6 {
    margin: 0;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.counter {
  background-color: var(--color-secondary-100);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  width: var(--spacing-9);
  height: var(--spacing-9);
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.action {
  color: inherit;
  font-size: 14px;
  font-weight: 700;
}
