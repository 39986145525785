.loader {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    height: 4px;
    width: 4px;
    margin-right: 4px;
    border-radius: 100%;
    background-color: currentColor;
    animation-name: blink;
    animation-duration: 950ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transform: scale(0);
  }

  & div:nth-child(1) {
    animation-delay: 0ms;
    transform-origin: 15% 50%;
  }

  & div:nth-child(2) {
    animation-delay: 150ms;
    transform-origin: 50% 50%;
  }

  & div:nth-child(3) {
    animation-delay: 300ms;
    transform-origin: 85% 50%;
  }
}

@keyframes blink {
  21% {
    transform: scale(1);
  }

  47% {
    transform: scale(1);
  }

  68% {
    transform: scale(0);
  }
}
