.loading {
  margin-top: var(--spacing-10);
}

.wrapper {
  align-items: center;
  background-color: var(--color-neutral-50);
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-10);
  padding: var(--spacing-3) var(--spacing-4);

  & svg {
    margin-right: var(--spacing-2);
  }

  & p {
    font-size: 14px;
    margin: 0;
  }

  & a {
    color: var(--color-neutral-700);
    font-weight: 400;
    text-decoration: underline;
  }
}
