.wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px 0;
  padding-bottom: var(--spacing-10);
  height: inherit;

  & h2 {
    margin-top: 0;
    margin-bottom: var(--spacing-5);
  }

  @media (--tablet) {
    gap: 80px 0;

    & h2 {
      margin-bottom: var(--spacing-10);
    }
  }
}
