.fieldGroup {
  gap: 12px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: var(--spacing-8) 0;

  @media (--mobile-s) {
    grid-template-columns: 1fr;
  }
}

.noBottomMargin {
  margin-bottom: var(--spacing-4);
}

.noTopMargin {
  margin-top: var(--spacing-4);
}

.wide {
  max-width: 100%;
}

.field {
  /* grid-column-start: span 1; */
  &[data-width='full'] {
    grid-column-start: 1;
    grid-column-end: 3;

    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  &[data-width='half'] {
    grid-column-start: 1;
    grid-column-end: 2;

    grid-column-end: 3;
    max-width: calc(50% - 6px);

    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
      max-width: unset;
    }
  }

  &[data-width='double'] {
    @media (--mobile-s) {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  &[data-id='date'] {
    & input {
      max-width: 100%;
      padding-right: var(--spacing-4);
    }
  }

  & textarea {
    padding: var(--spacing-2) var(--spacing-4) !important;
  }
}

.noMarginBottom {
  margin-bottom: 0;
}

.employmentDisclaimer {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-6);
}
