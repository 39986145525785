.title {
  margin: 0 0 var(--spacing-6) 0;
}

.steps {
  list-style: none;
  padding: 0;
  position: relative;
}

.step {
  margin-bottom: var(--spacing-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    border: 1px solid var(--color-tarmac-grey-50);
    left: 19px;
    height: 100%;
    position: absolute;
    top: 50%;
    bottom: 0;
    z-index: -1;
  }
}

.step .circle {
  background-color: var(--color-tarmac-grey-50);
  border: var(--spacing-1) solid var(--color-championship-white);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--spacing-3);
  height: 30px;
  width: 30px;
  padding: var(--spacing-4);

  & img {
    fill: var(--color-primary-500);
    height: 18px;
    width: 18px;
  }
}

.step:first-child:after {
  border-color: var(--color-success-500);
}

.step:first-child .circle {
  background-color: var(--color-success-500);

  & img {
    fill: var(--color-championship-white);
  }
}

.step:last-child:after {
  border-color: transparent;
}

.text {
  flex: 0 0 calc(100% - 64px);
}
