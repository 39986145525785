.summaryCardWrapper {
  display: none;
  flex-direction: column;
  gap: var(--spacing-6);
  margin-top: 0;

  &.mobileVersion {
    display: flex;
  }

  @media (--tablet-l) {
    margin-top: var(--spacing-10);
    display: flex;
    opacity: 1;
  }
}

.card {
  padding: var(--spacing-6);
  border: 1px solid var(--color-neutral-300);

  &.sticky {
    @media (--tablet) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      & > div {
        width: calc(50%);
        padding-right: var(--spacing-3);
      }
    }
  }
}

.mainSection {
}

.header {
  display: flex;
  flex-direction: row;
}

.title {
  margin: 0 0 var(--spacing-3);
}

.vehicleImage {
  height: 75.172px;
  aspect-ratio: 4/3;
  margin-right: var(--spacing-3);
}

.carDetail {
  display: flex;
  flex-direction: column;
}

.price {
  display: flex;
  justify-content: space-between;
  & > span:first-child {
    @media (--tablet) {
      font-size: 20px;
    }
  }
}

.priceDetail {
  margin: 0 !important;
}

.lineBreak {
  margin: var(--spacing-3) 0 !important;
  background: var(--color-tarmac-grey-100) !important;
}

.name {
  margin: 0;
}

.label {
  margin: 0 !important;
}

.dealerDetail {
  display: flex;
}

.uspList {
  & p:first-child {
    margin-top: 0;
  }

  & a {
    color: var(--color-primary-700) !important;

    & p {
      font-size: 12px;
      font-weight: 700;
    }
  }

  &.sticky {
    & a {
      display: block;
      margin-bottom: var(--spacing-3);
    }
    & p {
      margin: 0;
    }
    @media (--tablet) {
      padding-left: var(--spacing-3);
      border-left: 1px solid var(--color-mica-blue-50);
    }
  }

  @media (--tablet-l) {
    padding-left: 0;
    border-left: 0;
  }
}

.usp {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-3);
  align-items: center;
  font-size: 14px;
  margin-bottom: var(--spacing-1);
}

.stickySummaryWrapper {
  @media (--tablet-l) {
    display: none;
  }
}

.overlayWrapper {
  display: none;

  &.open,
  .summaryCardWrapper {
    display: flex;
  }
}

.stickySummaryCard {
  position: fixed;
  width: 100%;
  z-index: 96;
  left: 0;
  bottom: 63px;
  background-color: var(--color-championship-white);
  padding: var(--spacing-4);
}

.footer {
  position: fixed;
  width: 100%;
  height: 63px;
  z-index: 96;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px var(--spacing-1) var(--spacing-6) 0px rgba(38, 38, 38, 0.1);
  padding: var(--spacing-4) var(--spacing-6);
  border-top: 1px solid var(--color-primary-50);
  background-color: var(--color-championship-white);

  .price {
    align-items: flex-end;
  }

  .orderSummaryArrow {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary-500);
    padding-left: var(--spacing-1);

    & span {
      margin-right: var(--spacing-1);
    }
  }
}

.overlay {
  position: fixed;
  z-index: 95;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow: auto;
  background: var(--color-overlay);
}

.trustpilotWrapper {
  display: none;

  @media (--tablet-l) {
    display: block;
  }
}
