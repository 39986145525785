.wrapper {
  display: none;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-top: 0;
  opacity: 0;
  transition: 0.3s ease-in-out opacity;

  @media (--tablet) {
    margin-top: var(--spacing-10);
  }

  @media (--tablet-l) {
    display: flex;
  }

  &[data-is-mobile='true'] {
    display: flex;

    @media (--tablet-l) {
      display: none;
    }
  }

  &[data-is-visible='true'] {
    opacity: 1;
  }
}

.accordion {
  margin: 0 -20px;
  width: auto;

  > div {
    width: 100%;
    padding-left: var(--spacing-5);
    padding-right: var(--spacing-5);
  }

  &[data-is-mobile] {
    display: none;

    @media (--mobile) {
      display: block;
    }
  }

  @media (--tablet) {
    display: contents;

    & [data-accordion-component] {
      display: contents;
    }

    & [class*='headerArrow'],
    & [class*='default_accordion'],
    & [class*='default_header'] {
      display: none;
    }
  }

  &.hideMobile {
    @media (--mobile) {
      display: none;
    }
  }
}

.accordionTitle {
  margin: 0;
  padding: 0;
}

.vehicleInfo {
  display: none;
  flex-direction: row;
  gap: var(--spacing-3);
  min-height: 109px;

  @media (--tablet-l) {
    display: flex;
  }
}

.vehicleImage {
  height: 109px;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: var(--border-radius-2);
}

.vehicleDetails {
  display: flex;
  flex-direction: column;

  & * {
    margin: 0;
    padding: 0;
  }
}

.vehiclePrice {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.5px;
}

.carDetails {
  line-height: normal;

  @media (--mobile) {
    display: none;
  }
}

.dealerDetails {
  display: flex;
}

.title {
  margin: 0 0 var(--spacing-3);
  @media (--mobile) {
    display: none;
  }
}

.usps {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-neutral-500, #838383);
  padding: var(--spacing-6);

  @media (--mobile) {
    border: none;
    padding: 0;
  }
}

.usp {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-2);
  align-items: flex-start;
}

.uspList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 500;
  margin-bottom: 10px;

  [data-is-mobile='true'] & {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & .usp {
      flex: 1 1 40%;
    }

    @media (--mobile) {
      flex-direction: column;
      gap: 10px;
      & .usp {
        flex: unset;
      }
    }
  }
}

.price {
  display: flex;
  justify-content: space-between;
  & > span:first-child {
    @media (--tablet) {
      font-size: 20px;
    }
  }
}

.priceDetail {
  margin: 0 !important;
}

.linkModal {
  color: var(--color-neutral-700);
  cursor: pointer;
}

.modalContainer {
  @media (--tablet-l) {
    height: 635px;
    width: 800px;
  }
}

.modalBody {
  overflow: auto;
}
