.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  h2,
  p {
    margin: 0;
  }
  @media (--tablet) {
    flex-direction: row;
  }
}
.content {
  flex: 1 1 60%;
  padding: var(--spacing-5) var(--spacing-3) 0 var(--spacing-3);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  @media (--tablet) {
    padding: var(--spacing-5) var(--spacing-5);
  }
}

.image {
  display: flex;
  flex: 1 1 40%;
  position: relative;
  min-height: 232px;

  @media (--desktop) {
    min-height: 282px;
  }
}
.ctaGroup {
  margin-top: auto;
  padding-top: var(--spacing-2);
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--spacing-5);
  align-items: flex-start;
  @media (--tablet) {
    width: 60%;
    gap: var(--spacing-2);
  }
}
