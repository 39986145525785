.modalCtas {
  display: flex;
  flex-direction: column;

  gap: var(--spacing-4);

  @media (--tablet) {
    flex-direction: row;
    gap: var(--spacing-6);
  }
}
