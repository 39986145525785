.mobileDrawer {
  bottom: 0;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.isOpen {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    & .drawerContent {
      transform: translateY(0);
    }
  }
  @media (--desktop) {
    display: none;
  }
}

.drawerContent {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
  padding: var(--spacing-3) var(--spacing-5) var(--spacing-10);
  background-color: var(--color-championship-white);
  transform: translateY(110vw);
  transition: transform 0.3s ease 0.1s;
}

.options {
  margin: 0;
  padding: 0;
  width: calc(100% + 2px);
  list-style: none;
  overflow-y: auto;
  border-top: none;
}

.option {
  padding: var(--spacing-3) 0;
  cursor: pointer;
  max-height: var(--spacing-12);
  &.selected {
    padding-left: var(--spacing-2);
    border-left: solid var(--spacing-1) var(--color-neutral-700);
  }

  &:hover {
    background-color: var(--color-neutral-50);
  }
}

.optionContent {
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  line-height: var(--spacing-6);
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.drawerTitle {
  margin: var(--spacing-3) 0;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
