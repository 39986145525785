.stage {
  margin: var(--spacing-10) 0 var(--spacing-20);

  &.noMargin {
    @media (--mobile) {
      margin-top: 0;
    }
  }

  & h3 {
    margin-bottom: var(--spacing-3);
    margin-top: 0;

    & small {
      font-size: 16px;
    }
  }

  & a {
    color: var(--color-neutral-700);
    font-weight: 600;
  }
}

.buttonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: var(--spacing-2);
  margin: var(--spacing-6) 0 var(--spacing-2) 0;

  @media (--mobile) {
    grid-template-columns: 1fr;
  }
}

.button {
  margin-bottom: var(--spacing-5);
  width: 100%;

  @media (--mobile) {
    &:first-child {
      order: 2;
    }
  }
}

.p {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
}

.fields {
  & > div {
    margin-bottom: 0;
  }
}

.checkBox {
  & > div {
    margin: 0;
  }

  & > div:nth-child(2) {
    margin-bottom: var(--spacing-7);
  }

  a {
    color: var(--color-neutral-700);
    font-weight: 600;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }
}

.introMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.introIcon {
  margin-right: var(--spacing-2);
}

.disclaimer {
  & p {
    font-size: 12px;
    line-height: 20px;
    margin: 0;
  }
  & a {
    color: var(--color-neutral-700);
    font-weight: 600;
  }
}

.accordionTitle {
  flex: 1;
  text-align: right;
}

.terms,
.accordionTitle {
  & a {
    color: var(--color-neutral-700);
  }
}

.privacyPolicy {
  margin: var(--spacing-8) 0 0 0;
}

.optIn {
  & > div {
    margin: 0;
  }
}

.additionalSupport {
  margin-top: -20px;
  display: block;
}

.terms {
  margin-top: var(--spacing-8);
  h6 {
    margin: 0 0 var(--spacing-2) 0;
  }
  p {
    margin: 0;
  }
  > div {
    margin-top: var(--spacing-3);
  }
}

.summaryCard {
  display: block;

  @media (--tablet-l) {
    display: none;
  }
}

.introText {
  & h4 {
    margin: 0;
  }

  & div {
    margin: var(--spacing-2) 0 0;

    & p {
      display: inline;
    }
  }
}

.divider {
  border: none;
  border-bottom: 1px solid var(--color-neutral-100);
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-8);
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.paymentSkeleton {
  display: grid;
  gap: var(--spacing-2);
  grid-template-columns: repeat(2, 1fr);

  .wideBar,
  .bar {
    background-color: var(--color-neutral-300);
    height: 40px;
  }

  .wideBar {
    grid-column: 1 / -1; /* spans full width */
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  margin-bottom: var(--spacing-4);
  & h4 {
    margin: 0;
  }
}

.tooltip {
  display: inline-flex;
  align-items: center;
}
