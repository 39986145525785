.badgeWrapper {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  max-width: 110px;
  margin-left: var(--spacing-2);
}

.badge {
  width: 102px;
  height: 28px;
  padding: var(--spacing-1) var(--spacing-2);
  background-color: var(--color-tertiary-400);
  border-radius: 4px;
  cursor: default;
}

.hasMobileString {
  width: auto;

  span:first-child {
    @media (--mobile) {
      display: none;
    }
    display: block;
  }
  span:nth-child(2) {
    @media (--mobile) {
      display: block;
    }
    display: none;
  }
}

& [data-tenant='rac'] {
  .badge {
    background-color: var(--color-rac-orange-10);
  }
}
