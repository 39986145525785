.wrapper {
  max-width: 1312px;
  padding: var(--spacing-10) 0 var(--spacing-10) 0;
}

.confirmBody {
  margin-top: 0;

  @media (--mobile) {
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-4);
  }
}

.confirmDetails {
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  @media (--mobile) {
    align-items: center;
    flex-direction: column;
    gap: var(--spacing-4);
  }
}
.button {
  min-width: 200px;

  @media (--mobile) {
    width: 100%;
  }
}
.ctas {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--spacing-6) 0 var(--spacing-2) 0;

  & p {
    margin: 0;
  }

  @media (--mobile) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: var(--spacing-4);
    padding-bottom: var(--spacing-10);
    width: 100%;
  }
}

.modalContainer {
  && {
    @media (--mobile) {
      height: auto;
      position: absolute;
      bottom: 0;
    }

    @media (--tablet) {
      width: 800px;
    }
  }
}

.modalbody {
  && {
    padding: var(--spacing-8) var(--spacing-6);

    @media (--tablet) {
      padding: var(--spacing-8) var(--spacing-10);
    }
  }
}
