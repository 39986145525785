.fields {
  margin-top: var(--spacing-8);
  @media (--mobile) {
    margin-top: var(--spacing-3);
  }
  & h5 {
    margin: 0;
  }
  & p {
    margin: var(--spacing-4) 0px;
  }
}

.paymentError {
  margin-bottom: var(--spacing-4);
  border: 1px solid var(--color-red-500);
  background: var(--color-red-50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--spacing-2) 0px var(--spacing-2) var(--spacing-3);
}
.svgCircle {
  color: var(--color-championship-white);
  margin-right: var(--spacing-3);
  background: var(--color-red-500);
  border-radius: 60px;
  width: 40px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  min-height: 150px;
  position: relative;
}

.loadingWrapper {
  display: flex;
  flex-direction: column;
}
