.pagination {
  font-family: var(--font-family-system);
  list-style: none;

  & ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: var(--spacing-4);
  }

  & button,
  & a {
    all: unset;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    user-select: none;

    color: var(--color-neutral-700);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--spacing-10);
  }

  & .currentPage {
    color: var(--color-primary-500);
    border: var(--color-primary-500) 2px;
    border-bottom-style: solid;
    padding-top: 2px;
  }

  & .itemNumber {
    padding: 0 var(--spacing-3);
  }

  & .arrow {
    width: 40px;
  }

  & .ellipsis,
  & .slash {
    user-select: none;
    height: var(--spacing-10);
    display: flex;
    align-items: center;
  }

  & [aria-disabled='true'],
  & [disabled='true'] {
    pointer-events: none;
    cursor: not-allowed;
    color: var(--color-neutral-300);

    &:hover {
      background-color: transparent;
    }
    &:active {
      background-color: transparent;
    }
  }
}
