.container {
  position: relative;
  width: 100%;
  color: var(--option-text-color);

  &:focus {
    border-color: var(--container-focus-border-color);
  }

  & [data-mobile-only] {
    @media (--tablet-s) {
      display: none;
    }
  }
  & [data-desktop-only] {
    @media (--mobile) {
      display: none;
    }
  }

  &.disabled {
    color: var(--container-disabled-color);

    border-color: var(--container-disabled-color);
  }

  &.disabled:focus {
    color: var(--container-disabled-color);
    border-color: var(--container-disabled-color);
  }

  /* Error */
  & span[role='alert'] {
    padding-left: var(--spacing-3);
    font-size: 12px;
  }

  &.isOpen {
    & fieldset {
      border-color: var(--options-border-color) !important;
    }
  }
}

.clearIcon {
  cursor: pointer;
  &.disabled {
    circle {
      fill: var(--form-control-disabled-color);
    }
  }
}

.value {
  flex-grow: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  padding-left: var(--spacing-3);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.options {
  position: unset;
  margin: var(--spacing-3) 0 0 0;
  @media (--tablet-s) {
    position: absolute;
    top: 48px;
    border: 1px solid var(--options-border-color);
    border-top: none;
    margin: 0;
  }

  z-index: 100;
  display: none;

  padding: 0;
  width: 100%;
  max-height: 15em;
  list-style: none;
  overflow-y: auto;

  background-color: var(--color-championship-white);

  &.show {
    display: block;
  }
}

.option {
  padding: var(--spacing-3);
  cursor: pointer;
  max-height: var(--spacing-12);

  &.selected {
    padding-left: var(--spacing-2);
    border-left: solid var(--spacing-1) var(--color-neutral-700);
  }

  /* highlighted is the class for programitically hovered options */
  &.highlighted,
  &:hover {
    background-color: var(--color-neutral-50);
  }
}
