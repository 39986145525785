.articleCard {
  color: inherit;
  text-decoration: none;
}

.date {
  margin: var(--spacing-4) 0 0;
}

.title {
  margin: var(--spacing-2) 0 0;
  line-height: 1.42em;
}
.subtitle {
  margin: var(--spacing-2) 0 0;
}
.author {
  margin: var(--spacing-3) 0 0;
}
