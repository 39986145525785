.paginationWrapper {
  padding: var(--spacing-10);
  text-align: center;
  z-index: 1;
  content-visibility: auto;

  & > nav {
    display: flex;
    justify-content: center;
  }

  & .infoText {
    margin-top: 10px;
    margin-bottom: 0;
  }

  @media (--desktop) {
    padding: var(--spacing-8) 0 var(--spacing-10);

    & .infoText {
      display: none;
    }
  }
}
