.copy {
  & button {
    color: inherit;
    & span {
      font-size: 14px;
    }
  }
}

.loadingWrapper {
  margin-bottom: 0;
}

.leftSection {
  @media (--tablet-l) {
    max-width: 570px;
  }
  @media (--desktop-l) {
    max-width: 811px;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-bottom: var(--spacing-4);

  @media (--tablet) {
    flex-direction: row;
    margin-bottom: var(--spacing-8);
  }

  @media (--desktop) {
    &[data-has-finance='false'] {
      width: 50%;
    }
  }
}

.button {
  flex: 0;
  margin-bottom: 0;
  width: 100%;

  & span {
    color: var(--color-neutral-700);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: var(--spacing-6);

    & svg {
      margin-right: var(--spacing-2);
    }
  }

  &:hover span {
    color: var(--color-primary-500);
  }

  &:hover:after {
    border-color: var(--color-primary-500);
  }

  &:after {
    border-color: var(--color-neutral-300);
  }

  &[data-payment-method='true'] {
    background-color: var(--color-primary-50);

    & span {
      color: var(--color-primary-500);
    }

    &:after {
      border-color: var(--color-primary-500);
      border-width: 2px;
    }
  }
}
