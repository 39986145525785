.dealerInfoCard {
  padding: 0 0 var(--spacing-4) 0;

  @media (--tablet-l) {
    padding: var(--spacing-6) 0;
  }
  & h4 {
    margin: 0;
  }

  & .capitalizeLabel {
    text-transform: capitalize;
  }
}
.detailsList {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 var(--spacing-5);

  & .dealershipPlpLink {
    color: initial;
  }

  & li {
    gap: var(--spacing-3);
    align-items: center;
    display: flex;
    padding: 0 0 var(--spacing-6);
  }
}

.logoSection {
  align-items: center;
  display: flex;
  gap: var(--spacing-5);
  margin: var(--spacing-5) 0;

  @media (--tablet-l) {
    margin: var(--spacing-8) 0;
  }
}

.bookAppointmentCta {
  width: 100%;
  @media (--tablet) {
    max-width: 50%;
  }
  @media (--tablet-l) {
    max-width: 320px;
  }
}
