.modalContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    height: unset;
    width: 100%;
  }
  @media (--tablet-l) {
    height: unset;
    width: 800px;
  }
}

.modalBodyClassName {
  && {
    padding: 0;
  }
}
