.wrapper {
  background-color: var(--color-mica-blue-50);
  border: 1px solid var(--color-primary-500);
  border-radius: var(--spacing-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-2);
  margin-bottom: var(--spacing-4);
  padding: var(--spacing-8);

  @media (--mobile) {
    flex-direction: column-reverse;
    margin-bottom: var(--spacing-4);
    padding: var(--spacing-4);

    & h3 {
      font-size: 16px;
      line-height: 24px;
    }

    & svg {
      font-size: 32px !important;
    }
  }

  @media (--tablet-l) {
    margin-bottom: var(--spacing-8);
  }

  & p {
    margin-bottom: 0;
  }
}
