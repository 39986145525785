.locationFilter {
  padding: var(--spacing-2) 0 var(--spacing-4);

  & .infoLine {
    display: block;
    margin-bottom: var(--spacing-6);
  }

  & .conditionTypeBtns {
    margin-bottom: var(--spacing-6);
    width: 100%;
  }

  & .inputs {
    display: flex;
    margin-bottom: var(--spacing-5);

    & > div {
      width: 100%;
    }
  }

  & .postcode {
    text-transform: uppercase;
  }

  & .locationTypeBtns {
    margin-bottom: var(--spacing-6);
    width: 100%;
  }

  & .auxInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--spacing-5);

    & .resultCount {
      color: var(--color-neutral-500);
      padding-left: var(--spacing-1);
    }

    & button {
      padding: 0;
    }
  }
}
