.allArticles {
  padding-top: var(--spacing-6);
}

.title {
  margin: 0 0 var(--spacing-6);
}

.article {
  margin-bottom: var(--spacing-6);
}

.pagination {
  margin-top: var(--spacing-10);
  margin-bottom: var(--spacing-10);
}

@media (--tablet) {
  .allArticles {
    padding-top: var(--spacing-6);
  }
  .article {
    margin-bottom: var(--spacing-6);
  }
}

@media (--desktop) {
  .allArticles {
    padding-top: var(--spacing-8);
  }
  .title {
    margin-bottom: var(--spacing-8);
  }
  .article {
    margin-bottom: var(--spacing-8);
  }
}
