.input {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  margin: 0;
  outline: none;
  text-overflow: ellipsis;
  box-sizing: border-box;
  -webkit-appearance: none;
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
  color: var(--input-color);
  caret-color: var(--input-caret-color);

  /* Fixes Safari display bug */
  &[type='date'] {
    display: flex;
  }
}

.hasLeftIcon {
  padding-left: var(--spacing-1);
}

.hasRightIcon,
.hasLeftLabelIcon {
  padding-right: var(--spacing-1);
}

.input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-color);
}

.input::placeholder {
  color: var(--input-placeholder-color);
  font-weight: var(--font-weight-regular);
}

.input:disabled {
  color: var(--input-disabled-color);
  -webkit-text-fill-color: var(--input-disabled-color);
  cursor: var(--disabled-cursor);
  opacity: 1;
}

.input:read-only {
  color: var(--input-disabled-color);

  &:focus::placeholder {
    color: var(--input-disabled-color);
  }
}

& [data-tenant='rac'] {
  & .input {
    font-weight: 200;
  }
}

.inputFieldWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.leftLabelIcon {
  margin-left: var(--spacing-3);
  display: flex;
  align-items: center;
}
