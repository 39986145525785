.wrapper {
  display: none;
  align-items: center;
  gap: var(--spacing-3);

  > span {
    flex: 0 0 auto;
  }

  @media (--desktop) {
    display: flex;
  }
}
