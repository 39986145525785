.makeModelIntro {
  margin: -8px 0 24px 0;
  display: flex;
  gap: var(--spacing-4);

  &.expanded {
    flex-direction: column;
    gap: 0;
  }

  @media (--tablet) {
    margin: 0 0 40px 0;
  }
}

.copy {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;

  &.expanded {
    max-height: none;
    white-space: normal;
    overflow: visible;
  }

  @media (--tablet) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.viewMore {
  min-height: 0;
  text-decoration: underline var(--color-tarmac-grey-700);

  & span {
    color: var(--color-neutral-700);
    font-size: 12px;
    font-weight: 500;
  }

  &.expanded {
    margin: var(--spacing-4) 0 0 0;
    align-self: end;
  }

  @media (--tablet) {
    display: none;
  }
}
